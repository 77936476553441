<template>
  <footer class="main-footer sticky footer-type-1">
    <div class="footer-inner">
      <!-- 版权信息 -->
      <div class="footer-text">
        &copy; 2022-{{endTime}}
        <a href="#"><strong >云品导航</strong></a> 
        <p>
          Copyright © <a href="http://beian.miit.gov.cn" target="_blank">www.cloudpinlink.com</a> All Rights Reserved. 备案号：粤ICP备2024271150号
        </p>
      </div>
      
      <div class="go-up">
        <a href="#" rel="go-top"><i class="fa-angle-up"></i></a>
      </div>
    </div>
  </footer>
</template>

<script>
import dayjs from 'dayjs'
export default {
  name: 'Footer',
  data(){
    return {
      endTime:dayjs().format('YYYY')
    }
  }
}
</script>

<style>

</style>
