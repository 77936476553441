/*
 * @Author: Spenan songpan@jinggon.com
 * @Date: 2023-12-11 11:04:33
 * @LastEditors: Spenan songpan@jinggon.com
 * @LastEditTime: 2024-03-05 16:21:19
 * @FilePath: \SPENAN.NAVSITE.VUE\src\main.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import Vue from "vue";
import VueRouter from "vue-router";
import App from "./App.vue";
import Index from "./views/index.vue";
import About from "./views/about.vue";

import "./assets/css/fonts/linecons/css/linecons.css";
import "./assets/css/fonts/fontawesome/css/font-awesome.min.css";
import "./assets/css/bootstrap.css";
import "./assets/css/xenon-core.css";
import "./assets/css/xenon-components.css";
import "./assets/css/xenon-skins.css";
import "./assets/css/nav.css";

Vue.config.productionTip = false;
Vue.use(VueRouter);

const routes = [
  { path: "/", redirect: "/index" },
  { path: "/index", component: Index },
  { path: "/about", component: About },
  {
    path: "*",
    component: Index,
  },
];

const router = new VueRouter({
  routes,
  // mode: 'history'
});

new Vue({
  render: (h) => h(App),
  router,
}).$mount("#app");
